import React, { useState, useEffect} from 'react';
import { Alert } from 'react-bootstrap';
import { addAnsToFirebase, db, auth, st} from '../Firebase_Config/firebase';
import { collection, addDoc, getDocs, query, where, orderBy, limit,  doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL  } from 'firebase/storage';
import { Navigate } from 'react-router-dom';
import './self-assessment.css'




function AddedQuestions() {

    const group1 = "Business Continuity Planning"
    const group2 = "Disaster Recovery Planning"
    const group3 = "Business Impact Analysis"
 


    const questions = [
        { clause:'A.10.4(a)', text: 'Strategy Alignment- Is the business continuity strategy aligned with the overall business objectives and risk management framework of the organisation?', groupTitle:  group1},
        { clause:'A.10.4(b)', text: 'Plan Documentation- Are business continuity plans and procedures well-documented, up-to-date, and easily accessible to relevant stakeholders?', groupTitle: group1},
        { clause:'A.10.4(c)', text: 'Resource Allocation- Has the enterprise allocated sufficient resources (financial, human, technological) to implement and maintain the business continuity strategy effectively?', groupTitle:  group1},
        { clause:'A.10.4(d)', text: 'Testing and Training- How frequently are business continuity plans tested through simulations or drills, and are employees adequately trained to respond to various scenarios?', groupTitle:  group1},
        { clause:'A.10.4(e)', text: 'Vendor and Supply Chain Management- How does the enterprise ensure that key vendors and partners have robust business continuity plans in place to minimise disruptions to the supply chain?', groupTitle:  group1},
        { clause:'A.10.4(f)', text: 'Communication Protocols- Are there clear communication protocols established to disseminate information and instructions during a crisis or emergency situation?', groupTitle:  group1},
        { clause:'A.10.4(g)', text: 'Continuous Improvement- Does the enterprise conduct regular reviews and evaluations of its business continuity strategy to identify areas for improvement and incorporate lessons learned from past incidents?', groupTitle:  group1},
        // Group 2
        { clause:'A.11.4(a)', text: "Risk Assessment and Analysis: Conducting a comprehensive risk assessment to identify potential threats, vulnerabilities, and risks to the organization's IT infrastructure, systems, and data. This includes assessing natural disasters, such as earthquakes or floods, as well as human-made disasters like cyberattacks or data breaches.", groupTitle: group2},
        { clause:'A.11.4(b)', text: 'Business Impact Analysis (BIA): Performing a BIA to prioritize critical business processes, applications, and data that must be recovered in the event of a disaster. This helps determine recovery time objectives (RTOs) and recovery point objectives (RPOs) for each critical function or system.', groupTitle: group2},
        { clause:'A.11.4(c)', text: 'Backup and Data Protection: Implementing regular backups and data protection measures to ensure the integrity and availability of critical data. This may include both onsite and offsite backups, data replication, encryption, and other data protection mechanisms to prevent data loss or corruption during a disaster.', groupTitle: group2},
        { clause:'A.11.4(d)', text: "Disaster Recovery Strategies: Developing and implementing disaster recovery strategies to recover IT systems, applications, and data in the event of a disaster. This may involve strategies such as cold, warm, or hot site recovery, cloud-based disaster recovery, or hybrid approaches depending on the organization's requirements and budget.", groupTitle: group2},
        { clause:'A.11.4(e)', text: 'Emergency Response Procedures: Establishing emergency response procedures and protocols to ensure a swift and coordinated response to disasters. This includes defining roles and responsibilities, establishing communication channels, and conducting regular drills and exercises to test the effectiveness of the response plan.', groupTitle: group2},
        { clause:'A.11.4(f)', text: 'Infrastructure and Technology Resilience: Implementing resilient infrastructure and technology solutions to minimize downtime and ensure the continuity of critical operations during a disaster. This may involve redundant systems, failover mechanisms, high availability configurations, and other resilience measures.', groupTitle: group2},
        { clause:'A.11.4(g)', text: 'Incident Management and Reporting: Establishing incident management procedures for reporting, escalating, and resolving disasters or incidents. This includes establishing incident response teams, defining communication protocols, and documenting incident response procedures to ensure a coordinated and effective response.', groupTitle: group2},
        { clause:'A.11.4(h)', text: 'Training and Awareness: Providing training and awareness programs to educate employees, stakeholders, and key personnel about disaster recovery procedures, roles, and responsibilities. This helps ensure that everyone understands their roles and can effectively respond to a disaster when needed.', groupTitle: group2},
        { clause:'A.11.4(i)', text: 'Testing and Maintenance: Conducting regular testing, validation, and maintenance of the disaster recovery plan to ensure its effectiveness and reliability. This includes testing backup and recovery procedures, conducting tabletop exercises, and reviewing and updating the plan regularly to address changing threats and requirements.', groupTitle: group2},
        { clause:'A.11.4(j)', text: 'Compliance and Governance: Ensuring compliance with relevant regulations, industry standards, and best practices related to disaster recovery and business continuity. This may include regulatory requirements for data protection, privacy, and security, as well as industry standards such as ISO 22301 or NIST SP 800-34.', groupTitle: group2},
        // Group 3
        { clause:'A.12.4(a)', text: "Identifying Critical Business Functions: Identify and prioritize the critical business functions, processes, applications, and resources that are essential for the organization's operations. These may include customer service, financial transactions, supply chain management, IT systems, and other key business processes.", groupTitle: group3},
        { clause:'A.12.4(b)', text: 'Determining Recovery Time Objectives (RTOs): Define the maximum acceptable downtime for each critical business function. RTOs represent the time within which a business process or system must be restored after a disruption to avoid significant financial or operational impacts. RTOs are typically defined in terms of hours, days, or weeks.', groupTitle: group3},
        { clause:'A.12.4(c)', text: 'Estimating Recovery Point Objectives (RPOs): Determine the maximum acceptable data loss for each critical business function. RPOs represent the acceptable amount of data loss that can occur during a disruption before it becomes detrimental to the organization. RPOs are typically defined in terms of time intervals, such as minutes, hours, or days.', groupTitle: group3},
        { clause:'A.12.4(d)', text: "Assessing Dependencies and Interdependencies: Identify the dependencies and interdependencies between critical business functions, processes, applications, and resources. This includes understanding the relationships between different systems, data flows, dependencies on third-party vendors or suppliers, and other dependencies that may impact the organization's ability to recover from a disruption.", groupTitle: group3},
        { clause:'A.12.4(e)', text: 'Quantifying Impacts and Losses: Quantify the potential impacts and losses associated with disruptions to critical business functions. This may include financial impacts, such as revenue loss, increased costs, penalties, and regulatory fines, as well as non-financial impacts, such as damage to reputation, customer dissatisfaction, and legal liabilities.', groupTitle: group3},
        { clause:'A.12.4(f)', text: 'Identifying Resource Requirements: Determine the resources, including personnel, equipment, facilities, and technology, required to recover each critical business function within the defined RTOs and RPOs. This involves identifying the necessary resources for business continuity planning, disaster recovery, and crisis management.', groupTitle: group3},
        { clause:'A.12.4(g)', text: 'Risk Assessment and Prioritization: Assess the risks associated with disruptions to critical business functions and prioritize them based on their likelihood and potential impact. This involves analyzing the probability of different types of disruptions, such as natural disasters, cyberattacks, equipment failures, and human errors, and prioritizing mitigation efforts accordingly.', groupTitle: group3},
        { clause:'A.12.4(h)', text: "Documenting Findings and Recommendations: Document the findings of the BIA process, including critical business functions, RTOs, RPOs, dependencies, impacts, resource requirements, and risk assessments. Provide recommendations for mitigating risks, improving resilience, and enhancing the organization's ability to recover from disruptions.", groupTitle: group3},
        { clause:'A.12.4(i)', text: "Review and Update: Regularly review and update the BIA findings and recommendations to reflect changes in the organization's business environment, operations, technologies, and risks. Business impact analysis is an iterative process that should be periodically revisited to ensure its relevance and effectiveness.", groupTitle: group3},

      ];

      const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
      const [answers, setAnswers] = useState(Array(questions.length).fill(null));
      const [yesSelected, setYesSelected] = useState(false);
      const [fileToUpload, setFileToUpload] = useState(null);
      const [uploadedFiles, setUploadedFiles] = useState([]);
      const [isLoading, setIsLoading] =useState(false);
      const [mainContent, setMainContent]= useState(true)
      const storage = getStorage();




      useEffect(() => {
        const loadMostRecentData = async () => {
          const userId = getUserId();
          const answersRef = collection(db, 'userSavedAnswersExtended');
          const q = query(
            answersRef,
            where('UserId', '==', userId),
            orderBy('timestamp', 'desc'),
            limit(1)
          );
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach(doc => {
            const data = doc.data();
            setAnswers(data.answers);
          });
        };
        fetchUploadedFiles();
        loadMostRecentData();
      }, []);

      const isLastQuestion = currentQuestionIndex === questions.length - 1;
      const [validationError, setValidationError] = useState(null);

      const getUserId = () => {
        const user = auth.currentUser;
        return user.uid;
      };

      const saveHandler = async e => {
        e.preventDefault();

        const userId = getUserId();

        const answersRef = collection(db, 'userSavedAnswersExtended');
        const timestamp = new Date().toLocaleString();
        const answersData = {
          UserId: userId,
          answers: answers,
          timestamp: timestamp
        };

        try {
          const docRef = await addDoc(answersRef, answersData);
          console.log('Document written with ID: ', docRef.id);
          alert('Answers have been saved.');
        } catch (error) {
          console.error('Error adding document: ', error);
          alert('Error saving answers. Please try again.');
        }
      };

      
      const submitSaveHandler = async o => {
        const userId = getUserId();

        const answersRef = collection(db, 'userSavedAnswersExtended');
        const timestamp = new Date().toLocaleString();
        const answersData = {
          UserId: userId,
          answers: answers,
          timestamp: timestamp
        };

        try {
          const docRef = await addDoc(answersRef, answersData);
          console.log('Document written with ID: ', docRef.id);
        } catch (error) {
          console.error('Error adding document: ', error);
          alert('Error saving answers. Please try again.');
        }
      };
      

      const handleAnswer = (answer) => {
        const currentAnswer = answers[currentQuestionIndex];
        const newAnswers = [...answers];
      
          newAnswers[currentQuestionIndex] = {
            answer,
            comment: currentAnswer ? currentAnswer.comment : ''}
        
        
        setAnswers(newAnswers);
      
        if (answer != '0% - 9%') {
          setYesSelected(true);
        } else {
          setYesSelected(false);
        }
      };
      
    

      const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setYesSelected(false)
        }
      };

      const handlePrev = () => {
        if (currentQuestionIndex > 0) {
          setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
      };

      const handleCommentChange = comment => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = { ...newAnswers[currentQuestionIndex], comment };
        setAnswers(newAnswers);
      };

      const handleSubmit = async () => {
        const unansweredQuestionIndex = answers.findIndex(answer => !answer || answer.answer === null);
    
          if (unansweredQuestionIndex !== -1) {
              setValidationError(`Please answer question ${unansweredQuestionIndex + 1}`);
          } else {
            setMainContent(false)
            setIsLoading(true)
            setValidationError(null);
            const groupedAnswers = {};
            answers.forEach((answer, index) => {
                const groupTitle = questions[index].groupTitle;
                if (!groupedAnswers[groupTitle]) {
                    groupedAnswers[groupTitle] = [];
                }
                groupedAnswers[groupTitle].push({
                    question: questions[index].clause + ' ' + questions[index].text,
                    answer: answer,
                });
            });
    
            try {
                const userQuery = query(collection(db, 'users'), where('userId', '==', getUserId()));
                const userQuerySnapshot = await getDocs(userQuery);
    
                if (!userQuerySnapshot.empty) {
                    const userDocRef = userQuerySnapshot.docs[0].ref;
                    await updateDoc(userDocRef, { isAssessmentComplete: true });
                    console.log('User document updated successfully.');
                } else {
                    console.error('User document not found.');
                }
                submitSaveHandler()
                const submissionQuery = query(collection(db, 'submissions'), where('userId', '==', getUserId()), orderBy('timestamp', 'desc'), limit(1));
                const submissionQuerySnapshot = await getDocs(submissionQuery);
    
                submissionQuerySnapshot.forEach(async (doc) => {
                    try {
                        await updateDoc(doc.ref, { ExtendedQuestionsAnswers: groupedAnswers });
                        console.log('Submission document updated successfully.');
                    } catch (error) {
                        console.error('Error updating submission document:', error);
                    }
                });
            } catch (error) {
                console.error('Error querying documents:', error);
            }
            
            setTimeout(() => {
              window.location.href = '/Dashboard';
            }, 3000); 
        }
    };
    
    
    

      const fetchUploadedFiles = async () => {
        try {
          const userId = getUserId();
          const storageRef = ref(storage, `evidence/${userId}`);
          const fileList = await listAll(storageRef);
          
          const files = await Promise.all(fileList.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return {
              name: item.name,
              url: url
            };
          }));
          
          setUploadedFiles(files);
        } catch (error) {
          console.error('Error fetching uploaded files: ', error);
          alert('Error fetching uploaded files: ', error);
        }
      };

      const handleFileUpload = e => {
        const file = e.target.files[0];
        setFileToUpload(file);
      };
    
      const uploadEvidence = async () => {
        if (!fileToUpload) {
          console.error('No file selected.');
          alert('No file selected.');
          return;
        }
      
        const userId = getUserId();
        const storageRef = ref(storage, `evidence/${userId}/${fileToUpload.name}`);
      
        try {
          await uploadBytes(storageRef, fileToUpload);
          console.log('File uploaded successfully.');
          alert('File uploaded successfully.');
          setFileToUpload(null); // Clear the uploaded file after successful upload
          fetchUploadedFiles(); // Refresh the list of uploaded files
        } catch (error) {
          console.error('Error uploading file: ', error);
          alert('Error uploading file: ', error);
        }
      };
    
      const removeFile = async (indexToRemove, fileName) => {
        try {
          // Delete file from Firebase storage
          const userId = getUserId();
          const storageRef = ref(storage, `evidence/${userId}/${currentQuestionIndex}/${fileName}`);
          await deleteObject(storageRef);
          
          // Remove file from the list
          setUploadedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
          
          console.log('File deleted successfully.');
          alert('File deleted successfully.');
        } catch (error) {
          console.error('Error deleting file: ', error);
          alert('Error deleting file: ', error);
        }
      };

      return (
        <>
          {isLoading &&
            <h1>Loading....</h1>
          }
          {mainContent &&
            <div className="assessment-container">
              {validationError && <Alert variant="danger">{validationError}</Alert>}
              <table className="assessment-table">
                <thead>
                  <tr>
                    <th className='header-cell'>Clause</th>
                    <th className='header-cell'>Clause Description</th>
                    <th className='header-cell'>Implementation Status</th>
                    <th className='header-cell'>Remarks</th>
                  </tr>
                  <th colSpan="5" className='group-title'>{questions[currentQuestionIndex].groupTitle}</th>
                </thead>
                <tbody>
                  <tr>
                    <td className='question-numberContinue'>{questions[currentQuestionIndex].clause}</td>
                    <td className='question-text-container'>
                      <div className='question-text'>{questions[currentQuestionIndex].text}</div>
                    </td>
                    <td className='answersContinued'>
                      <form action='' className='container_self'>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="100%"
                            checked={answers[currentQuestionIndex]?.answer === '100%'}
                            onChange={() => handleAnswer('100%')}
                          />
                          <span className='Radio_text'>100% Complete (Fully carried out)</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="75% - 99%"
                            checked={answers[currentQuestionIndex]?.answer === '75% - 99%'}
                            onChange={() => handleAnswer('75% - 99%')}
                          />
                          <span className='Radio_text'>75% - 99% Complete (Near Completion)</span>
                        </label>
                          <label>
                            <input
                              type="radio"
                              name={`answer-${currentQuestionIndex}`}
                              value="50% - 74%"
                              checked={answers[currentQuestionIndex]?.answer === '50% - 74%'}
                              onChange={() => handleAnswer('50% - 74%')}
                            />
                            <span className='Radio_text'>50% - 74% Complete (Significant Advancement)</span>
                          </label>
                          <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="25% - 49%"
                            checked={answers[currentQuestionIndex]?.answer === '25% - 49%'}
                            onChange={() => handleAnswer('25% - 49%')}
                          />
                          <span className='Radio_text'>25% - 49% Complete (Much more to do)</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="10% - 24%"
                            checked={answers[currentQuestionIndex]?.answer === '10% - 24%'}
                            onChange={() => handleAnswer('10% - 24%')}
                          />
                          <span className='Radio_text'>10% - 24% Complete (An initial start has been made)</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="0% - 9%"
                            checked={answers[currentQuestionIndex]?.answer === '0% - 9%'}
                            onChange={() => handleAnswer('0% - 9%')}
                          />
                          <span className='Radio_text'>0% - 9% Complete (Nothing has been done)</span>
                        </label>
                      </form>
                    </td>
                    <td className='comments-box'>
                    <textarea
                      placeholder="Enter your comments here"
                      value={answers[currentQuestionIndex]?.comment || ''}
                      onChange={(e) => handleCommentChange(e.target.value)}
                    ></textarea>
                    {yesSelected && (
                      <div>
                        <input className='upButton'  type="file" onChange={handleFileUpload} />
                        <button className='fileButton' onClick={uploadEvidence}>Upload Evidence</button>
                      </div>
                    )}
                    {uploadedFiles.map((file, index) => (
                      <div key={index}>
                        <span>{file.name}</span>
                        <button className='fileButton remove' onClick={() => removeFile(index, file.name)}>Remove</button>
                      </div>
                    ))}
                    </td>
                  </tr>
                </tbody>
              </table>
        
              <div className="navigation-buttons"> 
                {currentQuestionIndex > 0 && (
                  <button className='Navbutton' onClick={handlePrev}>Previous</button>
                )}
              <button className='Navbutton' onClick={saveHandler}>Save Answers</button>
                {isLastQuestion ? (
                  <button className='Navbutton' onClick={handleSubmit}>Submit</button>
                ) : (
                  <button className='Navbutton' onClick={handleNext}>Next</button>
                )}
              </div>
              <hr />
            </div>
          }
        </>
      );

};
  
export default AddedQuestions;

import React, { useState, useEffect} from 'react';
import { Alert } from 'react-bootstrap';
import { addAnsToFirebase, db, auth, st} from '../Firebase_Config/firebase';
import { collection, addDoc, getDocs, query, where, orderBy, limit,  doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL  } from 'firebase/storage';
import './self-assessment.css'
import { set } from 'cohere-ai/core/schemas';




function SelfAssessment() {

    const group1 = "Assets: People – Equip employees with know-how to be the first line of defence"
    const group2 = "Assets: Hardware and software – Know what hardware and software the organisation has and protect them"
    const group3 = "Assets: Data – Know what data the organisation has, where they are, and secure the data"
    const group4 = "Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware"
    const group5 = "Secure/Protect: Access control – Control access to the organisation’s data and services"
    const group6 = "Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software"
    const group7 = "Update: Software updates – Update software on devices and systems"
    const group8 = "Backup: Back up essential data – Back up the organisation’s essential data and store them offline"
    const group9 = "Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents"



    const questions = [
        //Group1
        { clause:'A.1.4(a)', text: 'The organisation shall put in place cybersecurity awareness training for all employees to ensure that employees are aware of the security practices and behaviour expected of them. Organisations may meet this requirement in different ways, e.g., provide self-learning materials for employees or engaging external training providers.', SuggestArt: 'Documented information on cybersecurity awareness training for employees.', hasNotSureOption: false, groupTitle:  group1},
        { clause:'A.1.4(b)', text: 'Cyber hygiene practices and guidelines shall be developed for employees to adopt in their daily operations.', SuggestArt:'Documented information on cyber hygiene practices and guidelines for employees to adopt in their day-to-day operations.', hasNotSureOption: false, groupTitle:  group1},
        { clause:'A.1.4(c)', text: `The cyber hygiene practices and guidelines should include topics to mitigate cybersecurity incidents arising from the human factor as follows: 
        – Protect yourself from phishing; 
        – Set strong passphrase and protect them; 
        – Protect your corporate and/or personal devices (used for work);
        – Report cybersecurity incidents;
        – Handle and disclose business-critical data carefully; and
        – Work onsite and remotely in a secure manner.`, SuggestArt:'Documented information on content of cybersecurity awareness training.', hasNotSureOption: true, groupTitle:  group1},
        { clause:'A.1.4(d)', text: `Where feasible, the training content should be differentiated based on the role of the employees:
        – Senior management or business leaders – e.g., developing a cybersecurity culture/mindset in the organisation or establishing a cybersecurity strategy or workplan.
        – Employees – e.g., using strong passphrases and protecting the corporate and/or personal devices used for work.`, SuggestArt:'Documented information on differentiation of content of cybersecurity awareness training.', hasNotSureOption: true, groupTitle:  group1},
        { clause:'A.1.4(e)', text: 'As good practice, such cybersecurity awareness initiatives should be conducted at least annually to refresh employees’ awareness.', SuggestArt:'Documented information on frequency of cybersecurity awareness training.',  hasNotSureOption: true, groupTitle:  group1},
        //Group2
        { clause:'A.2.4(a)', text: 'An up-to-date asset inventory of all the hardware and software assets shall be maintained in the organisation. Organisations may meet this requirement in different ways, e.g., use of spreadsheet or IT asset management software to maintain the IT asset inventory.', SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(b)', text: `Hardware assets within the scope of certification may include servers, network devices, laptops and computers. If the scope of the certification includes hardware assets such as mobile devices and/or IoT devices:
        Mobile devices:
        – Organisations should include company-issued mobile devices as part of its asset inventory, e.g., mobile phone and tablet.
        IoT devices:
        – Organisations should include IoT devices used within the organisation as part of its asset inventory, e.g., Closed Circuit Television (CCTV), smart printer, smart television.`, SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: true, groupTitle:  group2},
        { clause:'A.2.4(c)', text: `The inventory list should contain details of the hardware assets where available as follows:
        – Hardware name/model;
        – Asset tag /serial number;
        – Asset type;
        – Asset location;
        – Network address;
        – Asset owner;
        – Asset classification;
        – Department;
        – Approval/authorised date; and
        – End of Support (EOS) date`, SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: true, groupTitle:  group2},
        { clause:'A.2.4(d)', text: `Software assets within the scope of certification may include software applications used by the organisation. If the scope of certification includes a cloud environment:
        Cloud
        – Organisation shall include what is hosted on the cloud instances, e.g., software and Operating System (OS).`, SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(e)', text: `The inventory list should contain the details of the software assets where available, as follows:
        – Software name;
        – Software publisher;
        – Software version;
        – Business purpose;
        – Asset classification;
        – Approval/authorised date; and
        – EOS date.`, SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: true, groupTitle:  group2},
        { clause:'A.2.4(f)', text: 'As good practice, the hardware and software asset inventory list should be reviewed at least bi-annually (twice per year).', SuggestArt:'Asset inventory of hardware and software assets.', hasNotSureOption: true, groupTitle:  group2},
        { clause:'A.2.4(g)', text: 'Hardware and software assets that are unauthorised or have reached their respective EOS shall be replaced.', SuggestArt:'Documented information of process and/or implementation of system to manage unauthorised and EOS assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(h)', text: 'In the event of any continued use of EOS assets, the organisation shall assess and understand the risk, obtain approval from senior management, and monitor it until the asset is replaced.', SuggestArt:'Documented information of process and/or implementation of system to manage unauthorised and EOS assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(i)', text: 'An authorisation process shall be developed to onboard new hardware and software into the organisation. Organisations may meet this requirement in different ways, e.g., email approval from senior management, ensuring that new hardware and software come from official or trusted sources, performing malware scans to verify that the asset is clean and maintaining asset whitelisting/blacklisting.', SuggestArt:'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(j)', text: 'The date of authorisation of software and hardware shall be keyed into the asset inventory list after obtaining the relevant dispensation, e.g., obtaining email approval or through the use of an approval form.', SuggestArt:'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(k)', text: 'Software and hardware without approval dates shall be removed.', SuggestArt:'Documented information of process and/or implementation of system to manage assets.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(l)', text: 'Before disposing of any hardware asset, the organisation shall ensure that all confidential information have been deleted, e.g., encrypting hard disk before reformatting and overwriting it.', SuggestArt:'Documented information on secure asset disposal.', hasNotSureOption: false, groupTitle:  group2},
        { clause:'A.2.4(m)', text: 'The organisation should carry out steps to ensure that the assets are disposed of securely and completely, e.g., destroy the hard disks physically or engage disk shredding services.', SuggestArt:'Documented information on secure asset disposal.', hasNotSureOption: true, groupTitle:  group2},
        //Group 3
        { clause:'A.3.4(a)', text: `The organisation shall identify and maintain an inventory of business-critical data  in the organisation. Organisations may meet this requirement in different ways, e.g., using spreadsheet or asset inventory software. The inventory list shall contain details of the data as follows:
        – Description;
        – Data classification and/or sensitivity;
        – Location; and
        – Retention period.`, SuggestArt:'Asset inventory of business-critical data.', hasNotSureOption: false, groupTitle:  group3},
        { clause:'A.3.4(b)', text: 'Review of the inventory list should be carried out at least annually, or whenever there is any change to the data captured by the organisation.', SuggestArt:'Asset inventory of business-critical data.', hasNotSureOption: true, groupTitle:  group3},
        { clause:'A.3.4(c)', text: 'The organisation shall establish a process to protect its business-critical data, e.g., password protected documents, encryption of personal data (at rest) and/or emails.', SuggestArt:'Documented information of process to protect business-critical data.', hasNotSureOption: false, groupTitle:  group3},
        { clause:'A.3.4(d)', text: 'There shall also be measures in place to prevent the employees from leaking confidential and/or sensitive data outside of the organisation, e.g., disabling USB ports.', SuggestArt:'Documented information and/or implementation of data leakage and disposal process.', hasNotSureOption: false, groupTitle:  group3},
        { clause:'A.3.4(e)', text: 'Before disposing of any paper-based (hard copy) media, the organisation shall carry out steps to ensure that those containing confidential and/or sensitive data have been securely shredded.', SuggestArt:'Documented information and/or implementation of data leakage and disposal process.', hasNotSureOption: false, groupTitle:  group3},
        //Group 4
        { clause:'A.4.4(a)', text: 'Anti-malware solutions shall be used and installed in endpoints to detect attacks on the organisation’s environment. Examples of endpoints include laptop computers, desktop computers, servers and virtual environments.', SuggestArt:'Documented information outlining functionality and implementation of on anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(b)', text: 'Virus and malware scans shall be carried out to detect possible cyberattacks. Where feasible, scans should always be automated and remain active to provide constant protection.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(c)', text: 'Organisations shall enable auto-updates or configure the anti-malware solution to update signature files or equivalent (e.g., non-signature based machine learning solutions) to detect new malware. Where possible, these updates should take place at least daily to stay protected from the latest malware.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(d)', text: 'Anti-malware solution shall be configured to automatically scan the files upon access. This includes files and attachments downloaded from the Internet through the web browser or email and external sources such as from portable USB drives.', SuggestArt:'Documented information on configuration of anti-virus/anti-malware solution.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(e)', text: `If the scope of certification includes mobile devices, IoT devices, cloud environment or use of web browser/email:
        Mobile devices:
        – Anti-malware solution should be installed and running on mobile devices.
        IoT devices:
        – Anti-malware solution should be integrated with the IoT devices, e.g., CCTV, smart television, smart printers, digital door lock.
        Cloud:
        – Anti-malware solution should be deployed on the cloud platform.
        Web browser/email:
        – Only fully supported web browsers and email client software with security controls should be used.
        – Anti-phishing and spam filtering tools should be established for the web browser/email client software.
        – Web browsers and/or email plug-ins/extensions/add-ons that are not necessary should be disabled and/or removed.
        – Web filtering should be deployed to protect the business from malicious sites, where feasible.`, SuggestArt:'Documented information outlining functionality and implementation of anti-virus/anti-malware solution.', hasNotSureOption: true, groupTitle:  group4},
        { clause:'A.4.4(f)', text: 'Firewalls shall be deployed or switched on to protect the network, systems, and endpoints such as laptops, desktops, servers, and virtual environments. In an environment where there is an organisation’s network setup, a network perimeter firewall shall be configured to analyse and accept only authorised network traffic into the organisation’s network. Examples can include packet filter, Domain Name System (DNS) firewall and application-level gateway firewall with rules to restrict and filter network traffic. Depending on the organisation’s network setup, the firewall functionality may be integrated with other networking devices or be a standalone device.', SuggestArt:'Network and/or systems diagram. Documented information on configuration of  firewall.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(g)', text: 'In an environment where there are endpoints connecting to the Internet and/or cloud-based applications, a software firewall (host-based firewall) should be configured and switched on for all the endpoints in the organisation where available, e.g., turning on the built-in software firewall feature included in most operating systems or anti-malware solutions.', SuggestArt:'Network and/or systems diagram. Documented information on configuration of  firewall.', hasNotSureOption: true, groupTitle:  group4},
        { clause:'A.4.4(h)', text: 'As good practice, firewall configurations and rules should ideally be reviewed and verified annually to protect the organisation’s Internet-facing assets where applicable.', SuggestArt:'Documented information on review of firewall rules and configuration.', hasNotSureOption: true, groupTitle:  group4},
        { clause:'A.4.4(i)', text: `If the scope of certification includes mobile and/or IoT devices:
        Mobile devices:
        – It is recommended that firewalls should be installed and enabled on employees’ mobile devices.
        IoT devices:
        – It is recommended that firewalls should be configured and enabled on IoT devices where possible.`, SuggestArt:'Documented information outlining functionality and implementation of firewall solution.', hasNotSureOption: true, groupTitle:  group4},
        { clause:'A.4.4(j)', text: 'The organisation shall ensure that its employees install/access only authorised software/attachments within the organisation from official or trusted sources.',SuggestArt:'Documented information on installation and access to authorised software/attachments.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(k)', text: 'The organisation shall ensure that employees are aware of the use of trusted network connections for accessing the organisation’s data or business email, e.g., mobile hotspot, personal Wi-Fi, corporate Wi-Fi and Virtual Private Network (VPN).', SuggestArt:'Content of cybersecurity awareness training to employees. Cybersecurity policies and practices for employees.', hasNotSureOption: false, groupTitle:  group4},
        { clause:'A.4.4(l)', text: 'The organisation shall ensure that its employees are aware of the need to report any suspicious email or attachment to the IT team and/or senior management immediately.', SuggestArt:'Content of cybersecurity awareness training to employees. Cybersecurity policies and practices for employees.', hasNotSureOption: false, groupTitle:  group4},
        // Group 5
        { clause:'A.5.4(a)', text: 'Account management shall be established to maintain and manage the inventory of accounts. The organisation may meet this in different ways, e.g., using of spreadsheets or exporting the list from software directory services.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(b)', text: `The account inventory list shall contain details for user, administrator, third-party, and service accounts not limited to the following:
        – Name;
        – Username;
        – Department;
        – Role/account type;
        – Date of access created; and
        – Last logon date.`, SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(c)', text: `The organisation shall have a process with the necessary approvals to grant and revoke access. The organisation may implement this in different ways, e.g., email approval or access request form. This shall be implemented when there are personnel changes such as onboarding of new staff or change of role(s) for employees. The following fields shall be captured as follows:
        – Name;
        – System to access;
        – Department;
        – Role/account type;
        – From date; and
        – To date.`, SuggestArt:'Documented information on process to request to grant and revoke access.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(d)', text: 'Access shall be managed to ensure employees can access only the information and systems required for their job role.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(e)', text: 'Accounts with access rights that are no longer required or have exceeded the requested date shall have their access disabled or removed from the system. Shared, duplicate, obsolete and invalid accounts shall be removed.', SuggestArt:'Inventory of user accounts. List of employees in the organisation, period of employment, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(f)', text: 'The administrator account shall only be accessed to perform administrator functions with approval from the senior management.', SuggestArt:'Documented information on process to request for administrative access to system.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(g)', text: 'Access shall be managed to ensure that third parties or contractors can access only the information and systems required for their job role. Such access shall be removed once they no longer require them.', SuggestArt:'Inventory of user accounts. List of third parties/contractors supporting the organisational, period of support, and their functional roles.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(h)', text: 'Third parties or contractors working with sensitive information in the organisation shall sign a non-disclosure agreement form. The form should include disciplinary action(s) for failure to abide by the agreement.', SuggestArt:'List of third parties/contractors supporting the organisational, period of support, and their functional roles. Non-disclosure agreement signed by third-parties and contractors.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(i)', text: 'Physical access control shall be enforced to allow only authorised employees/contractors to access the organisation’s IT assets and/or environment, e.g., use of cable lock to lock the workstations and card access door lock to authenticate and authorise entry.', SuggestArt:'Implementation of physical access control to organisation IT assets and/or environment. Documented information on process to request to grant and revoke physical  access.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(j)', text: 'As good practice, account reviews should be carried out at least quarterly or whenever there are changes to the account list, e.g., during onboarding and offboarding processes or organisation restructuring.', SuggestArt:'Documented information on the process to conduct account review. E.g. practices to grant and revoke access during employee onboard and offboarding.', hasNotSureOption: true, groupTitle:  group5},
        { clause:'A.5.4(k)', text: 'Dormant or inactive accounts which have been inactive for a prolonged period, e.g., sixty (60) days should be removed or disabled.', SuggestArt:'Inventory of user accounts.', hasNotSureOption: true, groupTitle:  group5},
        { clause:'A.5.4(l)', text: 'The organisation shall change all default passwords and replace them with a strong passphrase, e.g., it should be at least twelve (12) characters long and include upper case, lower case, and/or special characters.', SuggestArt:'Documented information on password or passphrase policy.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(m)', text: 'User accounts shall be disabled and/or locked out after multiple failed login attempts, e.g., after ten (10) failed login attempts, ’throttling’ the rate of attempts .', SuggestArt:'Documented information on policy for user accounts.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(n)', text: 'The account password shall be changed in the event of any suspected compromise.', SuggestArt:'Documented information on password or passphrase policy.', hasNotSureOption: false, groupTitle:  group5},
        { clause:'A.5.4(o)', text: 'Where feasible, two-factor authentication (2FA) should be used for administrative access to important systems, such as an Internet- facing system containing sensitive or business-critical data. Organisations may implement this in different ways, e.g., use of an authenticator application on the mobile or one-time password (OTP) token.', SuggestArt:'Documented information outlining functionality and implementation of two-factor authentication (2FA).', hasNotSureOption: true, groupTitle:  group5},
        { clause:'A.5.4(p)', text: 'Where feasible, trusted software to manage passphrases should be used to aide employee passphrase management.', SuggestArt:'Documented information outlining functionality and implementation of passphrase management solution.', hasNotSureOption: true, groupTitle:  group5},
        //Group 6
        { clause:'A.6.4(a)', text: 'Security configurations shall be enforced for the assets including desktop computers, servers and routers. Organisations may meet this requirement in different ways, e.g., adopting industry recommendations and standards such as Center for Internet Security (CIS) benchmarks on configuration guidelines across multiple vendor products, running baseline security analyser and/or using system configuration scripts.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
        { clause:'A.6.4(b)', text: 'Weak or default configurations shall be avoided or updated before using them, e.g., changing default password and performing deep scanning with anti-malware solution instead of standard scan.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
        { clause:'A.6.4(c)', text: 'Insecure configurations and weak protocols shall be replaced or upgraded to address the associated vulnerabilities, e.g., using Hypertext Transfer Protocol Secure (HTTPS) over normal Hypertext Transfer Protocol (HTTP) to encrypt data communication and upgrading Wired Equivalent Privacy (WEP) to Wi-Fi Protected Access 2/3 (WPA2/WPA3) to enhance the Wi-Fi security standards.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
        { clause:'A.6.4(d)', text: 'Features, services, or applications that are not in used shall be disabled or removed, e.g., disabling file sharing services, software macros and File Transfer Protocol (FTP) ports. ', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
        { clause:'A.6.4(e)', text: 'Automatic connection to open networks and auto-run feature of non-essential programs (other than backup or anti-malware solution, etc.) shall be disabled.', SuggestArt:'Documented information and/or implementation of configuration of assets. Network and/or systems diagram.', hasNotSureOption: false, groupTitle:  group6},
        { clause:'A.6.4(f)', text: 'Logging should also be enabled for software and hardware assets where feasible, e.g., system, events and security logs.', SuggestArt:'Documented information on logging of software and hardware assets.', hasNotSureOption: true, groupTitle:  group6},
        { clause:'A.6.4(g)', text: 'As good practice, automatic lock/session log out should be enabled after fifteen (15) min of inactivity for the organisation’s assets. These include user sessions on the laptop computer, server, non-mobile device, database, and administrator portal.', SuggestArt:'Documented information and/or implementation of configuration of assets.', hasNotSureOption: true, groupTitle:  group6},
        { clause:'A.6.4(h)', text: `If the scope of certification includes mobile devices, IoT devices, and/or cloud environment:
        Mobile devices – e.g., mobile phone, tablet:
        – Mobile devices should not be jail-broken or rooted.
        – Mobile device passcodes should be enabled.
        – Automatic mobile device locks should be activated after two (2) min of inactivity.
        – Mobile applications should only be downloaded from official or trusted sources.
        IoT devices:
        – Network hosting the IoT devices should be separated from the network containing the organisation’s assets and data.
        – Security features should be enabled on IoT devices, e.g., turning off device auto-discovery and Universal Plug and Play (UPnP).
        – In selecting IoT devices, the organisation should use devices rated by the Cybersecurity Labelling Scheme (CLS) (where available).
        Cloud:
        – Security logging and monitoring should be turned on for cloud visibility, e.g., history of Application Programming Interface (API) calls, change tracking and compliance.`, SuggestArt:'Documented information and/or implementation of configuration of assets.', hasNotSureOption: true, groupTitle:  group6},
        //Group 7
        { clause:'A.7.4(a)', text: 'The organisation shall prioritise the implementation of critical or important updates for operating systems and applications (e.g., security patches) to be applied as soon as possible.', SuggestArt:'Documented information and/or implementation of software updates and patches.', hasNotSureOption: false, groupTitle:  group7},
        { clause:'A.7.4(b)', text: 'The organisation should carry out compatibility tests on updates for operating system and applications before installing them.', SuggestArt:'Documented information and/or implementation of compatibility tests on updates for operating system and applications.', hasNotSureOption: true, groupTitle:  group7},
        { clause:'A.7.4(c)', text: 'The organisation should consider enabling automatic updates for critical operating system and application patches where feasible so that they can receive the latest updates.', SuggestArt:'Documented information and/or implementation of software updates and patches.', hasNotSureOption: true, groupTitle:  group7},
        { clause:'A.7.4(d)', text: `If the scope of certification includes mobile devices, IoT devices, and/or cloud environment:
        Mobile devices – e.g., mobile phone, tablet:
        – The organisation should ensure that updates and patches for mobile devices are only downloaded from trusted sources (e.g., official app store from the manufacturer).
        IoT devices:
        – The organisation should remove or replace any IoT devices (e.g., CCTV, printers) that are not receiving any software patches or updates.
        Cloud:
        – The organisation should refer to the cloud shared responsibility model with its Cloud Service Provider (CSP). This will allow organisations to be aware of when the organisation is responsible for software updates 
        and security patches, and when the CSP is responsible.
        – The organisation should have visibility on the software updates and security patches done by its CSPs.
        – The organisation should also have security requirements regarding software updates defined for its CSPs.`, SuggestArt:'Documented information on software updates and patches.', hasNotSureOption: true, groupTitle:  group7},
        //Group 8
        { clause:'A.8.4(a)', text: 'The organisation shall identify business-critical systems and those containing essential business information and perform backup. What needs to be backed up is guided by identifying what is needed for business recovery in the event of a cybersecurity incident. Examples of business-critical systems include stock-trading system, railway operating and control system. Examples of essential business information include financial data and business transactions.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(b)', text: 'The backups shall be performed on a regular basis, with the backup frequency aligned to the business requirements and how many days’ worth of data the organisation can afford to lose.', SuggestArt:'Documented information of systems backup. Records of backups.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(c)', text: 'For non-business-critical systems or non-essential information, the backups should still be performed but at/on a lower frequency/long term basis.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
        { clause:'A.8.4(d)', text: 'The backup process should be automated where feasible.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
        { clause:'A.8.4(e)', text: `If the scope of certification includes cloud environment:
        Cloud:
        – The organisation shall understand the role and responsibility between itself and the CSP in terms of data backup, e.g., cloud shared responsibility model, scope, and coverage of the cloud service.
        – Data backup shall be carried out by the organisation, e.g., storing the backups in a hard disk drive, purchasing the backup services by the CSP, and adopting multiple clouds to be used as backups.`, SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(f)', text: `If the scope of certification includes hardware assets such as mobile devices and/or IoT devices:
        Mobile devices:
        – Essential business information stored in mobile phones should be auto backed up and transferred to a secondary mobile phone or secondary storage for backup, e.g., SMS conversations or contact of an important 
        client.
        IoT devices:
        – IoT devices containing the organisation’s essential information should be backed up manually where automatic backup is not available, e.g., sensors in farms to improve operational safety and efficiency and in 
        healthcare to monitor patients with greater precision to provide timely treatment.`, SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
        { clause:'A.8.4(g)', text: 'All backups shall be protected from unauthorised access and be restricted to authorised personnel only. Backups should minimally be password-protected.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(h)', text: 'Backups shall be stored separately (i.e., offline) from the operating environment. Where feasible, backups should be stored offsite, e.g., separate physical location.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(i)', text: 'Frequent backups such as daily or weekly backups should be stored online to facilitate quick recovery, e.g., cloud backup storage.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: true, groupTitle:  group8},
        { clause:'A.8.4(j)', text: 'Longer term backups such as monthly backups shall be stored offline in an external secure storage location, e.g., password-protected USB flash drives, encrypted external hard disks and/or tape storage at an alternative office location.', SuggestArt:'Documented information and/or implementation of systems backup.', hasNotSureOption: false, groupTitle:  group8},
        { clause:'A.8.4(k)', text: 'As good practice, backups should be tested at least bi-annually, or more frequently, to ensure that business-critical systems and essential business information can be restored effectively.', SuggestArt:'Documented information and/or implementation of testing of systems backup.', hasNotSureOption: true, groupTitle:  group8},
        //Group 9
        { clause:'A.9.4(a)', text: `The organisation shall establish an up-to-date basic incident response plan to guide the organisation on how to respond to common cybersecurity incidents. Examples include phishing, data breach, ransomware. The plan shall contain details as follows:
        – Clear roles and responsibilities of key personnel in the organisation involved in the incident response plan process.
        – Procedures to detect, respond, and recover from the common cybersecurity threat scenarios, e.g., phishing, ransomware, data breach.
        – Communication plan and timeline to escalate and report the incident to internal and external stakeholders (such as regulators, customers, and senior management).`, SuggestArt:'Incident response plan with guidance on how to respond to common cyber incidents.', hasNotSureOption: false, groupTitle:  group9},
        { clause:'A.9.4(b)', text: 'The incident response plan shall be made aware to all employees in the organisation that have access to the organisation’s IT assets and/or environment.', SuggestArt:'Incident response plan with guidance on how to respond to common cyber incidents.', hasNotSureOption: false, groupTitle:  group9},
        { clause:'A.9.4(c)', text: 'The organisation should conduct post- incident review and incorporate learning points to strengthen and improve the incident response plan.', SuggestArt:'Incident response plan with  post-incident review, improvement of plan and review history.', hasNotSureOption: true, groupTitle:  group9},
        { clause:'A.9.4(d)', text: 'As good practice, the incident response plan should be reviewed at least annually.', SuggestArt:'Incident response plan with  post-incident review, improvement of plan and review history.', hasNotSureOption: true, groupTitle:  group9},
        


        

      ];

      const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
      const [answers, setAnswers] = useState(Array(questions.length).fill(null));
      const [yesSelected, setYesSelected] = useState(false);
      const [fileToUpload, setFileToUpload] = useState(null);
      const [uploadedFiles, setUploadedFiles] = useState([]);
      const [isLoading, setIsLoading] =useState(false);
      const [mainContent, setMainContent]= useState(true)
      const storage = getStorage();




      useEffect(() => {
        const loadMostRecentData = async () => {
          const userId = getUserId();
          const answersRef = collection(db, 'userSavedAnswers');
          const q = query(
            answersRef,
            where('UserId', '==', userId),
            orderBy('timestamp', 'desc'),
            limit(1)
          );
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach(doc => {
            const data = doc.data();
            setAnswers(data.answers);
          });
        };
        fetchUploadedFiles();
        loadMostRecentData();
      }, []);

      const isLastQuestion = currentQuestionIndex === questions.length - 1;
      const [validationError, setValidationError] = useState(null);

      const getUserId = () => {
        const user = auth.currentUser;
        return user.uid;
      };

      const saveHandler = async e => {
        e.preventDefault();

        const userId = getUserId();

        const answersRef = collection(db, 'userSavedAnswers');
        const timestamp = new Date().toLocaleString();
        const answersData = {
          UserId: userId,
          answers: answers,
          timestamp: timestamp
        };

        try {
          const docRef = await addDoc(answersRef, answersData);
          console.log('Document written with ID: ', docRef.id);
          alert('Answers have been saved.');
        } catch (error) {
          console.error('Error adding document: ', error);
          alert('Error saving answers. Please try again.');
        }
      };

      const submitSaveHandler = async o => {
        const userId = getUserId();

        const answersRef = collection(db, 'userSavedAnswers');
        const timestamp = new Date().toLocaleString();
        const answersData = {
          UserId: userId,
          answers: answers,
          timestamp: timestamp
        };

        try {
          const docRef = await addDoc(answersRef, answersData);
          console.log('Document written with ID: ', docRef.id);
        } catch (error) {
          console.error('Error adding document: ', error);
          alert('Error saving answers. Please try again.');
        }
      };

      const handleAnswer = (answer) => {
        const currentAnswer = answers[currentQuestionIndex];
        const newAnswers = [...answers];
      
        if (answer === 'no') {
          newAnswers[currentQuestionIndex] = {
            answer,
            comment: currentAnswer ? currentAnswer.comment : '',
            questionText: questions[currentQuestionIndex].text
          };
        } else {
          newAnswers[currentQuestionIndex] = {
            answer,
            comment: currentAnswer ? currentAnswer.comment : ''
          };
        }
        
        setAnswers(newAnswers);
      
        if (answer === 'yes') {
          setYesSelected(true);
        } else {
          setYesSelected(false);
        }
      };
      
    

      const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setYesSelected(false)
        }
      };

      const handlePrev = () => {
        if (currentQuestionIndex > 0) {
          setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
      };

      const handleCommentChange = comment => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = { ...newAnswers[currentQuestionIndex], comment };
        setAnswers(newAnswers);
      };

      const handleSubmit = async () => {
        const unansweredQuestionIndex = answers.findIndex((answer) => answer === null);

        if (unansweredQuestionIndex !== -1) {
          setValidationError(`Please answer question ${unansweredQuestionIndex + 1}`);
        } else {
          setMainContent(false)
          setIsLoading(true)
          setValidationError(null);
          // Define groups based on the provided ranges
          const groups = [
            { start: 0, end: 4, groupName: 'Assets: People – Equip employees with know-how to be the first line of defence'},
            { start: 5, end: 17, groupName: 'Assets: Hardware and software – Know what hardware and software the organisation has and protect them' },
            { start: 18, end: 22, groupName: 'Assets: Data – Know what data the organisation has, where they are, and secure the data' },
            { start: 23, end: 34, groupName: 'Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware' },
            { start: 35, end: 50, groupName: 'Secure/Protect: Access control – Control access to the organisation’s data and services' },
            { start: 51, end: 58, groupName: 'Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software' },
            { start: 59, end: 62, groupName: 'Update: Software updates – Update software on devices and systems' },
            { start: 63, end: 73, groupName: 'Backup: Back up essential data – Back up the organisation’s essential data and store them offline' },
            { start: 74, end: 77, groupName: 'Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents' }
          ];

          
      
          const groupedAnswers = {};

          groups.forEach((group) => {
            const startIndex = group.start;
            const endIndex = group.end;
            groupedAnswers[group.groupName] = {
              answers: answers.slice(startIndex, endIndex + 1)
            };
          });

          // Formatting and submitting grouped data to Firestore
          
          
          const frequencies = {};
          groups.forEach(group => {
            frequencies[group.groupName] = {
              withNotApplicable: { yes: 0, no: 0, 'not applicable': 0 },
              withoutNotApplicable: { yes: 0, no: 0}
            };
          });

          // Initialize questText object with grouped structure
          const questText = {};

          Object.entries(groupedAnswers).forEach(([groupName, groupData]) => {
            const groupIndex = groups.findIndex(group => group.groupName === groupName);
            const group = groups[groupIndex];
            const { start, end } = group;

            groupData.answers.forEach((answer, index) => {
              const questionIndex = start + index;
              const isNotApplicable = questions[questionIndex].hasNotSureOption;
              const category = isNotApplicable ? 'withNotApplicable' : 'withoutNotApplicable';

              frequencies[groupName][category][answer.answer] += 1;
            });
          });

          Object.entries(groupedAnswers).forEach(([groupName, groupData]) => {
            const groupIndex = groups.findIndex(group => group.groupName === groupName);
            const group = groups[groupIndex];
            const { start, end } = group;
          
            // Initialize arrays for question texts
            questText[groupName] = {
              questWithNotApplicable: [],
              questWithoutNotApplicable: []
            };
          
            groupData.answers.forEach((answer, index) => {
              const questionIndex = start + index;
              const isNotApplicable = questions[questionIndex].hasNotSureOption;
              const category = isNotApplicable ? 'questWithNotApplicable' : 'questWithoutNotApplicable';
          
              // Check if answer is 'no' before adding question text
              if (answer.answer === 'no') {
                questText[groupName][category].push(questions[questionIndex].text);
              }
            });
          });
          const submissionData = {
            timestamp: new Date().toLocaleString(),
            userId: getUserId(),
            Answers: groupedAnswers,
            Frequencies: frequencies,
            QuestionsText: questText
          };
      
          console.log("Submission data after grouping:", submissionData);
          submitSaveHandler()
          addAnsToFirebase(submissionData)
          setTimeout(() => {
            window.location.href = '/AddedQuestions';
          }, 3000); 
        }
        
      };

      const fetchUploadedFiles = async () => {
        try {
          const userId = getUserId();
          const storageRef = ref(storage, `evidence/${userId}`);
          const fileList = await listAll(storageRef);
          
          const files = await Promise.all(fileList.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return {
              name: item.name,
              url: url
            };
          }));
          
          setUploadedFiles(files);
        } catch (error) {
          console.error('Error fetching uploaded files: ', error);
          alert('Error fetching uploaded files: ', error);
        }
      };

      const handleFileUpload = e => {
        const file = e.target.files[0];
        setFileToUpload(file);
      };
    
      const uploadEvidence = async () => {
        if (!fileToUpload) {
          console.error('No file selected.');
          alert('No file selected.');
          return;
        }
      
        const userId = getUserId();
        const storageRef = ref(storage, `evidence/${userId}/${fileToUpload.name}`);
      
        try {
          await uploadBytes(storageRef, fileToUpload);
          console.log('File uploaded successfully.');
          alert('File uploaded successfully.');
          setFileToUpload(null); // Clear the uploaded file after successful upload
          fetchUploadedFiles(); // Refresh the list of uploaded files
        } catch (error) {
          console.error('Error uploading file: ', error);
          alert('Error uploading file: ', error);
        }
      };
    
      const removeFile = async (indexToRemove, fileName) => {
        try {
          // Delete file from Firebase storage
          const userId = getUserId();
          const storageRef = ref(storage, `evidence/${userId}/${fileName}`);
          await deleteObject(storageRef);
          
          // Remove file from the list
          setUploadedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
          
          console.log('File deleted successfully.');
          alert('File deleted successfully.');
        } catch (error) {
          console.error('Error deleting file: ', error);
          alert('Error deleting file: ', error);
        }
      };

      return (
        <>
          {isLoading &&
            <h1>Loading....</h1>
          }
          {mainContent &&
            <div className="assessment-container">
              {validationError && <Alert variant="danger">{validationError}</Alert>}
              <table className="assessment-table">
                <thead>
                  <tr>
                    <th className='header-cell'>Clause</th>
                    <th className='header-cell'>Clause Description</th>
                    <th className='header-cell'>Suggest Artefacts</th>
                    <th className='header-cell'>Implementation Status</th>
                    <th className='header-cell'>Remarks</th>
                  </tr>
                  <th colSpan="5" className='group-title'>{questions[currentQuestionIndex].groupTitle}</th>
                </thead>
                <tbody>
                  <tr>
                    <td className='question-number'>{questions[currentQuestionIndex].clause}</td>
                    <td className='question-text-container'>
                      <div className='question-text'>{questions[currentQuestionIndex].text}</div>
                    </td>
                    <td className='information-box'>{questions[currentQuestionIndex].SuggestArt}</td>
                    <td className='answers'>
                      <form action='' className='container_self'>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="yes"
                            checked={answers[currentQuestionIndex]?.answer === 'yes'}
                            onChange={() => handleAnswer('yes')}
                          />
                          <span className='Radio_text'>Yes</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`answer-${currentQuestionIndex}`}
                            value="no"
                            checked={answers[currentQuestionIndex]?.answer === 'no'}
                            onChange={() => handleAnswer('no')}
                          />
                          <span className='Radio_text'>No</span>
                        </label>
                        {questions[currentQuestionIndex].hasNotSureOption && (
                          <label>
                            <input
                              type="radio"
                              name={`answer-${currentQuestionIndex}`}
                              value="not applicable"
                              checked={answers[currentQuestionIndex]?.answer === 'not applicable'}
                              onChange={() => handleAnswer('not applicable')}
                            />
                            <span className='Radio_text'>Not applicable</span>
                          </label>
                        )}
                      </form>
                    </td>
                    <td className='comments-box'>
                    <textarea
                      placeholder="Enter your comments here"
                      value={answers[currentQuestionIndex]?.comment || ''}
                      onChange={(e) => handleCommentChange(e.target.value)}
                    ></textarea>
                    {yesSelected && (
                      <div>
                        <input className='upButton'  type="file" onChange={handleFileUpload} />
                        <button className='fileButton' onClick={uploadEvidence}>Upload Evidence</button>
                      </div>
                    )}
                    {uploadedFiles.map((file, index) => (
                      <div key={index}>
                        <span>{file.name}</span>
                        <button className='fileButton remove' onClick={() => removeFile(index, file.name)}>Remove</button>
                      </div>
                    ))}
                    </td>
                  </tr>
                </tbody>
              </table>
        
              <div className="navigation-buttons"> 
                {currentQuestionIndex > 0 && (
                  <button className='Navbutton' onClick={handlePrev}>Previous</button>
                )}
              <button className='Navbutton' onClick={saveHandler}>Save Answers</button>
                {isLastQuestion ? (
                  <button className='Navbutton' onClick={handleSubmit} >Next Page</button>
                ) : (
                  <button className='Navbutton' onClick={handleNext}>Next</button>
                )}
              </div>
              <hr />
            </div>
          }
        </>
      );

};
  
export default SelfAssessment;

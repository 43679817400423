import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCSGes3Bg9RQgRd-Ghfr64KVolOAde0rO0",
  authDomain: "cyber-risk-brazil-prod.firebaseapp.com",
  projectId: "cyber-risk-brazil-prod",
  storageBucket: "cyber-risk-brazil-prod.appspot.com",
  messagingSenderId: "996735413941",
  appId: "1:996735413941:web:9fa42f149cae30df8818fc",
  measurementId: "G-J5C59RF07L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const st = getStorage(app);
export const addAnsToFirebase = async (data) => {
  try {
    // Add a new document with a generated ID to the "submissions" collection
    const docRef = await addDoc(collection(db, "submissions"), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
    alert("Error adding document: ", e);
  }
};


export const auth = getAuth(app);
export { app, db, st };

import React, { useState, useEffect } from 'react';
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import GaugeChart from 'react-gauge-chart';
import RiskMeter from '../RiskMeter/RiskMeter';
import RiskMeter2 from '../RiskMeter/RiskMeter2';
import '../Dashboard/dashboard.css'


function AdminAssessmentDashboard() {

    const groupOrder =[
        "Assets: People – Equip employees with know-how to be the first line of defence",
        "Assets: Hardware and software – Know what hardware and software the organisation has and protect them",
        "Assets: Data – Know what data the organisation has, where they are, and secure the data",
        "Secure/Protect: Virus and malware protection – Protect from malicious software like viruses and malware",
        "Secure/Protect: Access control – Control access to the organisation’s data and services",
        "Secure/Protect: Secure configuration – Use secure settings for the organisation’s hardware and software",
        "Update: Software updates – Update software on devices and systems",
        "Backup: Back up essential data – Back up the organisation’s essential data and store them offline",
        "Respond: Incident response – Be ready to detect, respond to, and recover from cybersecurity incidents"
    ]

    const groupTableNames =[
        "Assets: People",
        "Assets: Hardware and software",
        "Assets: Data",
        "Secure/Protect: Virus and malware protection",
        "Secure/Protect: Access control",
        "Secure/Protect: Secure configuration",
        "Update: Software updates",
        "Backup: Back up essential data",
        "Respond: Incident response"
    ]
    
    const [withNAData, setWithNAData] = useState([]);
    const [withoutNAData, setWithoutNAData] = useState([]);
    const [overallComplianceScore, setOverallComplianceScore] = useState(0);
    const [riskOfNonCompliance, setRiskOfNonCompliance] = useState(0);
    const [sixteenQuestRisk, setSixteenQuestRisk] = useState(0);
    const [sectorScoreCal, setsectorScoreCal] = useState(0);
    const [sectorName, setSectorName] = useState('');
    const [employeeScoreCal, setemployeeScoreCal] = useState(0);
    const [isGraphsActive, setIsGraphsActive] = useState(false); 
    const [showTables, setShowTables] = useState(false); 
    const [sixteenTables, setsixteenTables] = useState(true); 
    const [userAnswers, setUserAnswers] = useState([]);
    const [userOraganName, setUserOraganName] = useState('');
    const [selectedGroupQuestions, setSelectedGroupQuestions] = useState([]);
    const [finalQuestText, setFinalQuestText] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [adminTrue, setAdminTrue] = useState(null);
    const [addedQuestAnswers, setAddedQuestAnswers] =useState([])
    const [showTable2, setShowTable2] =useState(false)
    const [groupWiseQuestions, setGroupWiseQuestions] = useState({});
    const [showTable3, setShowTable3] =useState(false)







    useEffect(() => {
        const fetchDataAndRiskScore = async () => {
            try {
                const userId = getUserId();
                console.log('User ID:', userId); // Add this line for debugging
    
                // Fetch risk score
                const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
                const userDocSnapshot = await getDocs(userDocQuery);
    
                if (!userDocSnapshot.empty) {
                    const userDocRef = userDocSnapshot.docs[0].ref;
                    const userDocData = userDocSnapshot.docs[0].data();
                    const riskScore = userDocData.Risk_Score || 0; // Default to 0 if Risk_Score is not available
                    const sectorScore =userDocData.Sector_Score ||0;
                    const employeeScore =userDocData.Employee_Score ||0;
                    const getSectorName = userDocData.sector || null;
                    const getOraganName = userDocData.OrganName|| null;
                    setSixteenQuestRisk(riskScore);
                    setemployeeScoreCal(employeeScore)
                    setsectorScoreCal(sectorScore)
                    setSectorName(getSectorName)
                    setUserOraganName(getOraganName)
                    console.log(sectorName)
                    console.log('Risk Score:', riskScore); 
                    setUserAnswers(userDocData.Answers)

                    
                } else {
                    console.log('User document not found for user ID:', userId);
                }
    
                // Fetch submission data
                const submissionQuery = query(collection(db, 'submissions'), where('userId', '==', userId), orderBy('timestamp', 'desc'), limit(1));
                const submissionSnapshot = await getDocs(submissionQuery);
    
                if (!submissionSnapshot.empty) {
                    const data = submissionSnapshot.docs[0].data();
                    console.log('Submission Data:', data); // Add this line for debugging
                    const { withNAData, withoutNAData } = processData(data);
                    const questionsObject = data.QuestionsText;
                    const questionsArray = Object.entries(questionsObject).map(([question, description]) => {
                        return {
                            question,
                            description
                        };
                    });
                    console.log('Question text:', questionsArray); 
                    console.log('Question text:', typeof questionsArray); 
                    setSelectedGroupQuestions(questionsArray);
                    console.log('Processed Data:', withNAData, withoutNAData); // Add this line for debugging
                    setWithNAData(withNAData);
                    setWithoutNAData(withoutNAData);
                    const groups = ['Business Continuity Planning', 'Disaster Recovery Planning', 'Business Impact Analysis'];
                    const groupQuestions = {};
                    groups.forEach(group => {
                        if (data.ExtendedQuestionsAnswers[group]) {
                            groupQuestions[group] = data.ExtendedQuestionsAnswers[group];
                        }
                    });
                    setGroupWiseQuestions(groupQuestions);
                    console.log('group', groupQuestions);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const checkAdminPrivileges = async () => {
            const userAdmin = auth.currentUser.uid; 
            try {     
                const userDocQuery = query(collection(db, 'users'), where('userId', '==', userAdmin));
                const userDocSnapshot = await getDocs(userDocQuery);

                if (!userDocSnapshot.empty) {
                    const userDocData = userDocSnapshot.docs[0].data();
                    const isAdminTrue = userDocData.isAdmin || null;
                    setAdminTrue(isAdminTrue);
                } else {
                    console.log('User document not found for user ID:', userAdmin);
                }


            } catch (error) {
                console.error('Error checking admin privileges:', error);
            }
        };
    
        checkAdminPrivileges();
        fetchDataAndRiskScore();
    }, []);
    
    
    
    
    
    
    

    useEffect(() => {
        const { overallComplianceScore, riskOfNonCompliance } = calculateOverallCompliance(withoutNAData, withNAData);
        setOverallComplianceScore(overallComplianceScore);
        setRiskOfNonCompliance(riskOfNonCompliance);
    }, [withoutNAData, withNAData]);

    const getUserId = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const userId = searchParams.get('id');
        return userId;
    };

    const calculateOverallCompliance = (withoutNAData, withNAData) => {
        const shallCompliancePercentage = 2.08
        const shallImplementedControls = withoutNAData.reduce((total, row) => total + row.implementedCount, 0);
        const shallScore = shallCompliancePercentage * shallImplementedControls;

        const shouldTotalQuestions = 30;
        const shouldTotalNotApplicable = withNAData.reduce((total, row) => total + row.notApplicableCount, 0);
        const shouldAdjustedTotalQuestions = shouldTotalQuestions - shouldTotalNotApplicable;
        const shouldCompliancePercentage = shouldAdjustedTotalQuestions > 0 ? 100 / shouldAdjustedTotalQuestions : 0;
        const shouldImplementedControls = withNAData.reduce((total, row) => total + row.implementedCount, 0);
        const shouldScore = shouldCompliancePercentage * shouldImplementedControls;

        // Calculate overall compliance score
        const overallScore = (shallScore + shouldScore) / 2;

        // Calculate risk of non-compliance
        const risk = 100 - overallScore;

        return {
            overallComplianceScore: overallScore.toFixed(2),
            riskOfNonCompliance: risk.toFixed(2)
        };
    };

    function calculateTotalRiskScore(sixteenQuestRisk, riskOfNonCompliance) {
        // Convert the values to numbers
        const numericRiskScore = parseFloat(sixteenQuestRisk);
        const numericRiskOfNonCompliance = parseFloat(riskOfNonCompliance);
        
        // Check if both values are valid numbers
        if (!isNaN(numericRiskScore) && !isNaN(numericRiskOfNonCompliance)) {
            // Calculate the average of the two scores
            const totalRiskScore = (numericRiskScore + numericRiskOfNonCompliance) / 2;
            return totalRiskScore.toFixed(2); // Return the result rounded to 2 decimal places
        } else {
            return null; // Return null if either value is not a valid number
        }
    }
    const calculateLikelihoodScore = () => {
        const X = sectorScoreCal + employeeScoreCal;
        console.log('X:', X);
        const Y = X / 2;
        console.log('Y:', Y);
        console.log(totalRiskScore)
        const  XX = Y + parseFloat(totalRiskScore);
        console.log('XX:', XX);
        const YY = XX / 2;
        console.log('YY:', YY);
        let likelihoodScore = YY;

        if (totalRiskScore > 50) {
            likelihoodScore += 5;
        }

        console.log('Likelihood of Attack in next 12 months score:', likelihoodScore);

        return likelihoodScore;
    };

    const totalRiskScore = calculateTotalRiskScore(sixteenQuestRisk, riskOfNonCompliance);
    const likelihoodOfAttackScore = calculateLikelihoodScore();
    

    const processData = (data) => {
        const withNAData = [];
        const withoutNAData = [];

        // Iterate over each group in the data
        Object.entries(data).forEach(([category, attributes]) => {
            Object.entries(attributes).forEach(([groupName, counts]) => {
                if (counts.withNotApplicable) {
                    const implementedCount = counts.withNotApplicable.yes || 0;
                    const notImplementedCount = counts.withNotApplicable.no || 0;
                    const notApplicableCount = counts.withNotApplicable['not applicable'] || 0;

                    withNAData.push({
                        groupName,
                        implementedCount,
                        notImplementedCount,
                        notApplicableCount
                    });
                }

                if (counts.withoutNotApplicable) {
                    const implementedCount = counts.withoutNotApplicable.yes || 0;
                    const notImplementedCount = counts.withoutNotApplicable.no || 0;

                    withoutNAData.push({
                        groupName,
                        implementedCount,
                        notImplementedCount
                    });
                }
            });
        });

        return { withNAData, withoutNAData };
    };

    const handleGraphChange = () => {
        setShowTables(false)
        setShowTable2(true)
    }
    const handleGraphChange2 = () => {
        setShowTable2(false)
        setShowTable3(true)
    } 
    const handleGraphChange3 = () => {
        setShowTable3(false)
        setIsGraphsActive(true)
    } 
    const handleGraphChange4 = () => {
        setIsGraphsActive(false)
        setShowTable3(true)
    }
    const handleGraphChange5 = () => {
        setShowTable3(false)
        setShowTable2(true)
    }
    const handleGraphChange6 = () => {
        setShowTable2(false)
        setShowTables(true)
    }
    const handleGraphChange7 = () => {
        setShowTables(false)
        setsixteenTables(true)
    }
    const handleGraphChange8 = () => {
        setsixteenTables(false)
        setShowTables(true)
    }

    const calculateMedianSectorScore = (sectorName) => {
        let sectorMediumScore = 0;
        switch (sectorName) {
            case "Manufacturing":
                sectorMediumScore = 10500;
                break;
            case "Finance and insurance":
                sectorMediumScore = 32814 ;
                break;
            case "Professional, business, and consumer services":
                sectorMediumScore = 7941 ;
                break;
           case "Energy":
                sectorMediumScore = 35439;
                break;
            case "Retail and wholesale":
                sectorMediumScore = 29533;
                break;
            case "Education":
                sectorMediumScore = 31762;
                break;
            case "Healthcare":
                sectorMediumScore = 29532;
                break;
            case "Government":
                sectorMediumScore = 16407;
                break;
            case "Transportation":
                sectorMediumScore = 26251;
                break;
            case "Media and telecom":
                sectorMediumScore = 13125;
                break;
            case "Other":
                sectorMediumScore = 123;
                break;
            default:
                sectorMediumScore = 31007;
                break;
        }
        return sectorMediumScore;
    };
    const medianSectorScore = calculateMedianSectorScore(sectorName)
    const handleGroupClick = (groupName) => {
        // Find the selected group in the selectedGroupQuestions array
        const selectedGroup = selectedGroupQuestions.find(group => group.question === groupName);
        
        // Check if the group is found
        if (selectedGroup) {
            console.log('Selected group:', selectedGroup);
    
            // Extract the questWithoutNotApplicable data
            const questWithoutNotApplicable = selectedGroup.description.questWithoutNotApplicable;
            
            console.log('questWithoutNotApplicable for', groupName, ':', questWithoutNotApplicable);
    
            // Perform further actions with questWithoutNotApplicable data
            console.log('Final result:', questWithoutNotApplicable);
            setFinalQuestText(questWithoutNotApplicable)
            setIsDialogOpen(true)

        } else {
            console.log('Selected group not found:', groupName);
        }
    };

    const handleGroup2Click = (groupName) => {
        // Find the selected group in the selectedGroupQuestions array
        const selectedGroup = selectedGroupQuestions.find(group => group.question === groupName);
        
        // Check if the group is found
        if (selectedGroup) {
            console.log('Selected group:', selectedGroup);
    
            // Extract the questWithoutNotApplicable data
            const questWithNotApplicable = selectedGroup.description.questWithNotApplicable;
            
            console.log('questWithoutNotApplicable for', groupName, ':', questWithNotApplicable);
    
            // Perform further actions with questWithoutNotApplicable data
            console.log('Final result:', questWithNotApplicable);
            setFinalQuestText(questWithNotApplicable)
            setIsDialogOpen(true)

        } else {
            console.log('Selected group not found:', groupName);
        }
    };
    
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div>
            {adminTrue ? (
                <>
                <h1 className='dashboardHeading'>{`Admin Dashboard for User - ${userOraganName}`}</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {sixteenTables &&
                        <div style={{ margin: 'auto', width: 'calc(50% - 10px)', fontFamily: 'Noto Sans, sans-serif' }} >
                            <div className='dashboardTable'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td colspan="2" class="table-title">Answers to 16 Questions</td>
                                        </tr>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userAnswers.map((answer, index) => (
                                            <tr key={index}>
                                                <td className= "dashboardTable tdColor">{answer.question}</td>
                                                <td className= "dashboardTable tdColor">{answer.answer}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>            
                                <div style={{ position: 'absolute', bottom: '0', right: '0', margin: '20px' }}>
                                    <button type='button' className="nextButton" onClick={handleGraphChange8}>
                                        <span>Next Page</span>
                                        <svg width="40px" height="40px" viewBox="0 0 24 24" >
                                            <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {showTable2 &&
                         <>
                         <>
                             <div style={{ marginLeft: '20px', width: 'calc(48% - 10px)', fontFamily: 'Noto Sans, sans-serif' }} >
                                 <div className='dashboardTable'>
                                     <table>
                                         <thead>
                                             <tr>
                                                 <td colspan="2" class="table-title">Business Continuity Planning</td>
                                             </tr>
                                             <tr>
                                                 <th>Question</th>
                                                 <th className='answer-column'>Answer</th>
                                             </tr>
                                         </thead>
                                         <tbody>
                                         {groupWiseQuestions['Business Continuity Planning'].map((questionAnswer, index) => (
                                             <tr key={index}>
                                                 <td className= "dashboardTable tdColor">{questionAnswer.question}</td>
                                                 <td className= "dashboardTable tdColor">{questionAnswer.answer.answer}</td>
                                             </tr>
                                         ))}
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
                             <div style={{ marginLeft: '20px', width: 'calc(48% - 10px)', fontFamily: 'Noto Sans, sans-serif', zIndex: '3' }}>
                                 <div className='dashboardTable'>
                                     <table>
                                         <thead>
                                             <tr>
                                                 <td colspan="2" class="table-title">Disaster Recovery Planning</td>
                                             </tr>
                                             <tr>
                                                 <th>Question</th>
                                                 <th className='answer-column'>Answer</th>
                                             </tr>
                                         </thead>
                                         <tbody>
                                         {groupWiseQuestions['Disaster Recovery Planning'].map((questionAnswer, index) => (
                                             <tr key={index}>
                                                 <td className= "dashboardTable tdColor">{questionAnswer.question}</td>
                                                 <td className= "dashboardTable tdColor">{questionAnswer.answer.answer}</td>
                                             </tr>
                                         ))}
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
                             
                         </>
                             <div style={{ position:'fixed', bottom:'0', left: '0', margin: '20px', marginBottom:'75px', zIndex: '2' }}>
                                 <button type='button' className="nextButton prev" onClick={handleGraphChange6}>
                                     <svg width="40px" height="40px" viewBox="0 0 24 24">
                                         <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>
                                     <span>Previous Page</span>
                                 </button> 
                             </div>
                             <div style={{ position:'fixed', bottom:'0', right: '0', margin: '20px', marginBottom:'75px', zIndex: '2' }}>
                                 <button type='button' className="nextButton" onClick={handleGraphChange2}>
                                     <span>Next Page</span>
                                     <svg width="40px" height="40px" viewBox="0 0 24 24" >
                                         <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>
                                 </button>
                             </div> 
                         </>  
                    }
                    {showTable3 &&
                        <div style={{ margin: 'auto', width: 'calc(50% - 10px)', fontFamily: 'Noto Sans, sans-serif' }} >
                            <div className='dashboardTable'>
                            <table>
                                    <thead>
                                        <tr>
                                            <td colspan="2" class="table-title">Business Impact Analysis</td>
                                        </tr>
                                        <tr>
                                            <th>Question</th>
                                            <th className='answer-column'>Answer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {groupWiseQuestions['Business Impact Analysis'].map((questionAnswer, index) => (
                                        <tr key={index}>
                                            <td className= "dashboardTable tdColor">{questionAnswer.question}</td>
                                            <td className= "dashboardTable tdColor">{questionAnswer.answer.answer}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>      
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                    <button type='button' className="nextButton prev" onClick={handleGraphChange5}>
                                        <svg width="40px" height="40px" viewBox="0 0 24 24">
                                            <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span>Previous Page</span>
                                    </button>
                                    <button type='button' className="nextButton" onClick={handleGraphChange3}>
                                        <span>Next Page</span>
                                        <svg width="40px" height="40px" viewBox="0 0 24 24" >
                                            <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {showTables &&
                        <>
                            <div style={{ marginLeft: '20px', width: 'calc(48% - 10px)', fontFamily: 'Noto Sans, sans-serif' }}> 
                                <div className='dashboardTable'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td colspan="4" class="table-title">Requirements ("shall" statements)</td>
                                            </tr>
                                            <tr>
                                                <th class="group-column">Group</th>
                                                <th class="total-column">Total</th>
                                                <th class="implemented-column">Implemented</th>
                                                <th class="not-implemented-column">Not Implemented</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupOrder.map((groupName, index) => {
                                                const row = withoutNAData.find(row => row.groupName === groupName);
                                                if (row) {
                                                    return (
                                                        <tr key={index} onClick={() => handleGroupClick(groupName)}>
                                                            <td className='groupName'>{groupTableNames[index]}</td>
                                                            <td className= "dashboardTable tdColor">{row.implementedCount + row.notImplementedCount}</td>
                                                            <td className= "dashboardTable tdColor">{row.implementedCount}</td>
                                                            <td className= "dashboardTable tdColor">{row.notImplementedCount}</td>
                                                        </tr>
                                                    );
                                                } else {
                                                
                                                    return null;
                                                }
                                            })}
                                        <tr className='summaryCollum'>
                                            <td>Overall summary</td>
                                            <td>{withoutNAData.reduce((total, row) => total + row.implementedCount + row.notImplementedCount, 0)}</td>
                                            <td>{withoutNAData.reduce((total, row) => total + row.implementedCount, 0)}</td>
                                            <td>{withoutNAData.reduce((total, row) => total + row.notImplementedCount, 0)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                            <div style={{ marginRight: '20px', width: 'calc(48% - 10px)' }}>
                                <div className='dashboardTable'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td colspan="5" class="table-title">Requirements ("should" statements)</td>
                                            </tr>
                                            <tr>
                                                <th class="group-column">Group</th>
                                                <th class="total-column">Total</th>
                                                <th class="implemented-column">Implemented</th>
                                                <th class="not-implemented-column">Not Implemented</th>
                                                <th class="not-applicable-column">Not Applicable</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupOrder.map((groupName, index) => {
                                                const row = withNAData.find(row => row.groupName === groupName);
                                                if (row) {
                                                    return (
                                                        <tr key={index} onClick={() => handleGroup2Click(groupName)}>
                                                            <td className='groupName'>{groupTableNames[index]}</td>
                                                            <td className= "dashboardTable tdColor">{row.implementedCount + row.notImplementedCount + row.notApplicableCount}</td>
                                                            <td className= "dashboardTable tdColor">{row.implementedCount}</td>
                                                            <td className= "dashboardTable tdColor">{row.notImplementedCount}</td>
                                                            <td className= "dashboardTable tdColor">{row.notApplicableCount}</td>
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        <tr className='summaryCollum'>
                                            <td>Overall summary</td>
                                            <td>{withNAData.reduce((total, row) => total + row.implementedCount + row.notImplementedCount + row.notApplicableCount, 0)}</td>
                                            <td>{withNAData.reduce((total, row) => total + row.implementedCount, 0)}</td>
                                            <td>{withNAData.reduce((total, row) => total + row.notImplementedCount, 0)}</td>
                                            <td>{withNAData.reduce((total, row) => total + row.notApplicableCount, 0)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                        <DialogTitle className='dialogCentre'>Feedback</DialogTitle>
                        <DialogContent>
                        {finalQuestText && finalQuestText.length > 0 ? (
                            <div>
                                <ul>
                                    {finalQuestText.map((item, index) => (
                                        <li key={index}>
                                            <p><strong>Not Implemented:</strong> {item}</p>
                                    
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <p>All questions in this group are fully implemented.</p>
                        )}
                        </DialogContent>
                        <DialogActions>
                            <button  className="nextButton" onClick={handleCloseDialog}>Close</button>
                        </DialogActions>
                    </Dialog>

                    {isGraphsActive && (
                        <div class='graphBox'>
                            <div class='riskMeters'>
                                <div class='riskMeterContainer'>
                                    <p class='title'>Risk of Non-Compliance</p>
                                    <p class='number'>{riskOfNonCompliance}%</p>
                                    <RiskMeter score={riskOfNonCompliance}/>
                                </div>
                                <div class='riskMeterContainer'>
                                    <p class='title'>Total Risk Score</p>
                                    <p class='number'>{totalRiskScore}%</p>
                                    <RiskMeter score={totalRiskScore}/>
                                </div>
                            </div>
                            <div class='riskMeterContainer'>
                                    <p class='title'>Median likely immediate cost of cyber attack</p>
                                    <p class='number'>{medianSectorScore} USD</p>
                                    <RiskMeter2 score={medianSectorScore}/>
                                </div>
                            <div class='gaugeChartContainer'>
                                <div class='gaugeChartWrapper'>
                                    <p class='title'>Likelihood of Attack in the next 12 months</p>
                                    <div class='gaugeChart'>
                                        <GaugeChart
                                            id="risk-score1"
                                            nrOfLevels={3}
                                            style={{width: '300px'}}
                                            arcsLength={[0.34, 0.33, 0.33]}
                                            cornerRadius={0}
                                            textColor={'#050052'}
                                            colors={['#DDF2FD', '#427D9D', '#0F1035']}
                                            percent={likelihoodOfAttackScore/100}
                                            arcPadding={0.02}
                                        />
                                    </div>
                                </div>
                                <div class='gaugeChartWrapper'>
                                    <p class='title'>Likelihood of Attack in the {sectorName} Sector</p>
                                    <div class='gaugeChart'>
                                        <GaugeChart
                                            id="risk-score2"
                                            nrOfLevels={3}
                                            style={{width: '300px'}}
                                            arcsLength={[0.34, 0.33, 0.33]}
                                            cornerRadius={0}
                                            textColor={'#050052'}
                                            colors={['#DDF2FD', '#427D9D', '#0F1035']}
                                            percent={sectorScoreCal/100}
                                            arcPadding={0.02}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
                {showTables && (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <button type='button' className="nextButton prev" onClick={handleGraphChange7}>
                                <svg width="40px" height="40px" viewBox="0 0 24 24">
                                    <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Previous Page</span>
                            </button>
                            <button type='button' className="nextButton" onClick={handleGraphChange}>
                                <span>Next Page</span>
                                <svg width="40px" height="40px" viewBox="0 0 24 24" >
                                    <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </>
                )}

                {isGraphsActive &&
                    <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <button type='button' className="nextButton prev" onClick={handleGraphChange4}>
                            <svg width="40px" height="40px" viewBox="0 0 24 24">
                                <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Previous Page</span>
                        </button>
                    </div>
                </> 
                }
                </>
            ) : (
                <h1 className='textCentre'>You do not have Admin Permissions</h1>
            )}
        </div>
    );
}

export default AdminAssessmentDashboard;
